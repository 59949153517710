
import { Vue, Component } from 'vue-property-decorator';
import User from '@/models/User';
import { IformCreateOption } from 'ant-design-vue/types/form/form';
import { LoginValues } from '@/interfaces/components/LoginValues';
import { RouteNames } from '@/enums/routes/RouteNames';
import { TAnyKeyValueObject } from 'jsona/lib/JsonaTypes';
import { Col } from 'ant-design-vue';
import { Row } from 'ant-design-vue';
import { ErrorOptions } from '@/interfaces/ErrorOptions';

interface DynamicObject {
    [key: string]: any;
}

@Component({
    name: 'LoginForm',
    components: {
        Col,
        Row,
    },
})
export default class LoginForm extends Vue {
    private httpRequestStarted: boolean = false;
    private requiredFieldNames = ['username', 'password'];
    private routeNames = RouteNames;

    private get form() {
        return this.$form.createForm(this, { name: 'horizontal_login' } as IformCreateOption);
    }

    private mounted() {
        this.setDefaultLanguage();
    }

    private setDefaultLanguage() {
        const cacheLang = localStorage.getItem('languageLocale');

        if (cacheLang) {
            this.$i18n.locale = cacheLang;
        } else {
            const browserLang = window.navigator.language;
            const locale = browserLang.length === 2 ? browserLang : browserLang.slice(0, browserLang.indexOf('-'));
            this.$i18n.locale = locale;
        }
    }

    private async login(loginValues: LoginValues) {
        this.httpRequestStarted = true;
        const resetErrors: DynamicObject = {};
        this.requiredFieldNames.forEach((fieldName: string) => {
            resetErrors[fieldName] = {};
            resetErrors[fieldName].value = loginValues[fieldName] ? loginValues[fieldName] : '';
            resetErrors[fieldName].errors = [];
        });
        // resets errors before submitting the form
        this.form.setFields(resetErrors);
        try {
            await User.login(loginValues);
        } catch (e) {
            const errors: DynamicObject = {};
            const errorObj = e as ErrorOptions;

            if (errorObj.response?.data.errors) {
                errorObj.response.data.errors.forEach((errorDetails: TAnyKeyValueObject) => {
                    const field = errorDetails.source.pointer.split('/')[3];
                    if (errors[field] === undefined) {
                        errors[field] = {};
                        errors[field].value = loginValues[field] ? loginValues[field] : '';
                        errors[field].errors = [];
                    }
                    errors[field].errors.push(errorDetails.detail);
                });
            }
            this.form.setFields(errors);
            this.httpRequestStarted = false;
            return;
        }

        // Clear cache storage before redirecting
        sessionStorage.clear();

        const redirectTo = this.$route.query.redirectTo;

        if (redirectTo) {
            this.$router.push({ path: redirectTo as string });
        } else {
            this.$router.push({ name: RouteNames.statisticsDashboard });
        }

        this.httpRequestStarted = false;
    }

    private hasErrors(fieldsError: any) {
        return Object.keys(fieldsError).some((field) => fieldsError[field]);
    }

    private userNameError() {
        const { getFieldError } = this.form;

        return getFieldError('username') ? getFieldError('username')[0] : '';
    }

    private passwordError() {
        const { getFieldError } = this.form;

        return getFieldError('password') ? getFieldError('password')[0] : '';
    }

    private handleSubmit(event: Event) {
        event.preventDefault();

        this.form.validateFields(
            // @ts-ignore
            (err: Error, loginValues: LoginValues) => {
                if (!err) {
                    this.login(loginValues);
                }
            }
        );
    }
}
