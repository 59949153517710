
import { Vue, Component } from 'vue-property-decorator';
import LoginForm from '@/components/views/login/LoginForm.vue';
import RoltekHeaderLogo from '@/icons/RoltekHeaderLogo.vue';
import RoltekBanner from '@/icons/RoltekBanner.vue';
import { Col } from 'ant-design-vue';
import { Row } from 'ant-design-vue';

@Component({
    name: 'Login',
    components: {
        LoginForm,
        RoltekHeaderLogo,
        RoltekBanner,
        Col,
        Row,
    },
})
export default class Login extends Vue {}
